





























































































import { OperatorIPAddress, OperatorIPProcessResponse } from '@/api/schema';
import {
  getOperatorIPAddresses,
  createOperatorIPAddress,
  deleteOperatorIPAddress,
  updateOperatorIPAddress
} from '@/api/Onboarding';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ip, isIpAddress } from '@/helpers/validation';

export interface IPAddressData {
  id?: number;
  ipAddress?: string;
  edit: boolean;
  processing?: boolean;
}

const IPData: IPAddressData = {
  ipAddress: '',
  edit: false,
  processing: false
};

const getDefaultIpsState = (): IPAddressData[] => [
  { edit: true },
  { edit: true }
];

export default {
  name: 'OnboardingWhiteListIpContent',
  data: (): unknown => ({
    ipAddresses: getDefaultIpsState(),
    rules: [(v) => !v || ip(v)],
    copyProcessing: false,
    prodIps: []
  }),
  computed: {
    ...mapState('Onboarding', ['operatorIPs', 'operators']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['isTestEnv']),
    prodOperatorId(): string {
      return this.operators.find(({ test }) => !test)?.id;
    },
    isSaveButtonDisabled(): boolean {
      const isChangedIPsArr = this.changedIPs.filter((ip) => ip.ipAddress);

      return !(
        isChangedIPsArr.length &&
        isChangedIPsArr.every((ip) => this.isIp(ip.ipAddress, true))
      );
    },
    changedIPs(): Array<any> {
      return this.ipAddresses?.filter((ip) => ip.edit || !ip.id) || [];
    },
    ipsToTransfer(): string[] {
      if (!this.isTestEnv) return [];

      const ips = this.prodIps.map(({ ipAddress }) => ipAddress);

      return this.ipAddresses.filter(
        ({ ipAddress }) => !ips.includes(ipAddress)
      );
    }
  },
  watch: {
    operatorIPs: {
      handler(newData: OperatorIPAddress[]): void {
        if (newData.length === 0) {
          this.ipAddresses = getDefaultIpsState();
        } else {
          this.ipAddresses = newData.map((ip: OperatorIPAddress) => ({
            ...IPData,
            ...ip
          }));
        }
      },
      deep: true,
      immediate: true
    }
  },

  mounted(): void {
    if (this.isTestEnv) {
      this.getProdIps();
    }
  },

  methods: {
    ...mapActions('Onboarding', ['getOperatorIPs']),
    getProdIps(): Promise<void> {
      return getOperatorIPAddresses(this.prodOperatorId).then((result) => {
        this.prodIps = result;
      });
    },
    isIp: isIpAddress,
    async saveAllIPs(): Promise<void> {
      await Promise.allSettled(
        this.changedIPs.map((ip, index) =>
          this.saveOperatorIP(ip, index, false)
        )
      ).then(() => {
        window.dataLayer.push({
          event: 'token_info_section_finished',
          eventCategory: 'onboarding',
          eventAction: 'status_update',
          eventLabel: 'whitelist_ips_section_finished'
        });
        this.getOperatorIPs().then((): void => {
          this.$toast.success('IPs have been saved in Whitelist');
          this.$emit('updateProgress');
        });
      });
    },
    saveOperatorIP(
      data: OperatorIPAddress,
      index: number,
      notify: boolean = true
    ): Promise<void> {
      const { ipAddress } = data;

      if (!isIpAddress(ipAddress)) return;
      this.updateIPData({ processing: true }, index);

      const handler = data.id
        ? updateOperatorIPAddress
        : createOperatorIPAddress;

      return handler(data)
        .then((res: OperatorIPProcessResponse) => {
          if (notify) {
            this.$toast.success('IP have been saved in Whitelist');
            this.getOperatorIPs().then(() => this.$emit('updateProgress'));
          }
          this.updateIPData(
            {
              ...(!data.id && { id: res.id }),
              processing: false,
              edit: false
            },
            index
          );
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
          this.updateIPData({ processing: false }, index);
        });
    },
    updateIPData(data: { [key: string]: any }, index: number): void {
      this.$set(this.ipAddresses, index, {
        ...this.ipAddresses[index],
        ...data
      });
    },
    addIP(): void {
      this.ipAddresses.push({ ...IPData, edit: true });
    },
    editIP(index: number): void {
      this.updateIPData({ edit: true }, index);
    },
    deleteIP(data: OperatorIPAddress | IPAddressData, index: number): void {
      this.updateIPData({ processing: true }, index);
      deleteOperatorIPAddress(data.id)
        .then(() => {
          this.ipAddresses.splice(index, 1);
          this.$toast.success('IP Deleted.');
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
          this.updateIPData({ processing: false }, index);
        });
    },
    async saveToProd(): Promise<void> {
      this.copyProcessing = true;

      try {
        await Promise.allSettled(
          this.ipsToTransfer.map((ip) =>
            createOperatorIPAddress(ip, this.prodOperatorId)
          )
        );
        await this.getProdIps();

        this.$toast.success(
          `IP's have been transferred to Production Whitelist`
        );
      } catch (e) {
        errorToastMessage(e);
      } finally {
        this.copyProcessing = false;
      }
    }
  }
};

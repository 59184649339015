
















































































import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import AntennaIcon from '@/assets/images/icons/antenna-icon.svg?inline';
import FAQListItem from '@/components/FAQListItem.vue';
import { mapGetters, mapState } from 'vuex';
import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import ExternalLink from '@/components/links/ExternalLink.vue';

export default {
  name: 'OnboardingTrafficProgress',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    AntennaIcon,
    FAQListItem,
    CodeBlock,
    ExternalLink
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.trafficProgress
  }),
  computed: {
    ...mapState('Onboarding', ['onboardingData']),
    ...mapGetters('Onboarding', ['isProxy']),
    ...mapGetters('app', ['isCopyStakeRole']),
    url(): string {
      const url = this.onboardingData.proxy
        ?.replaceAll(' ', '')
        .replace(/\/?$/, '');
      const suffix = '/api/v1/accept';

      return url?.endsWith(suffix) ? url : `${url}${suffix}`;
    }
  },
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'game_transactions_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'game_transactions_section_opened'
        });
      }
    }
  },
  methods: {
    copyURLAction(): void {
      window.dataLayer.push({
        event: 'trueplay_ip_copy_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'trueplay_ip_copy'
      });
    }
  }
};





















import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import UserSupportIcon from '@/assets/images/icons/user-support-icon.svg?inline';
import { EOnbordingStep, ERole } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import InviteUserInput from '@/components/invite-user/InviteUserInput.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingUserSupport',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    InviteUserInput,
    UserSupportIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.userSupportProgress,
    email: ''
  }),
  computed: {
    ...mapGetters('app', ['isCopyStakeRole']),
    type(): ERole {
      return this.isCopyStakeRole
        ? ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT
        : ERole.ROLE_OPERATOR_SUPPORT;
    },
    getDescriptionTitle(): string {
      return this.isCopyStakeRole
        ? 'Invite Support Manager to assist with inquiries from CopyStake users.'
        : 'Invite Support Manager to assist with inquiries from Loyalty Program users.';
    }
  },
  methods: {
    handleInvite(): void {
      this.$emit('updateProgress', this.onboardingStepName);
    }
  }
};






























import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import DocumentIcon from '@/assets/images/icons/document-icon.svg?inline';
import FAQListItem from '@/components/FAQListItem.vue';
import OnboardingWhiteListIpContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingWhiteListIpContent.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingWhitelistIP',
  mixins: [OnboardingMixin],
  components: {
    FAQListItem,
    ExpansionProgressPanel,
    DocumentIcon,
    OnboardingWhiteListIpContent
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.whitelistProgress,
    faqImages: [
      {
        name: 'onboarding',
        src: '/images/onboarding/faq-ip-whitelist-onboarding.webp',
        width: 207,
        height: 200
      }
    ]
  }),
  computed: {
    ...mapGetters('app', ['isCopyStakeRole'])
  },
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'whitelist_ips_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'whitelist_ips_section_opened'
        });
      }
    }
  },
  methods: {
    updateProgress(): void {
      if (this.isCompleted) return;

      this.$emit('updateProgress', EOnbordingStep.whitelistProgress);
    }
  }
};
